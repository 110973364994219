<template>
  <div class="feedback-wrapper" :class="{ 'feedback-wrapper--open': feedbackOpen }">
    <div class="feedback">
      <div v-if="feedbackOpen" class="feedback__open flex flex-column">
        <div v-if="feedbackSent" class="center">
          {{ $t('endResultsThanksForFeedback') }}
        </div>
        <template v-else>
          <div class="mb3 feedback__title">{{ $t('adminPanel.help.feedback') }}</div>
          <ul class="feedback__rating flex">
            <li
              class="feedback__rating-item flex items-center justify-center"
              v-for="i in 10"
              :key="i"
              @click="feedbackRating = i"
              :class="{ 'feedback__rating-item--active': feedbackRating === i }"
            >
              {{ i }}
            </li>
          </ul>
          <Textarea
            :placeholder="$t('endResultsFeedbackPlaceholder')"
            v-model="feedback"
            rows="5"
          />
          <BaseButtonWithSpinner :loading="feedbackLoading" @click="handleSendFeedback">
            {{ $t('endResultsSendFeedback') }}
          </BaseButtonWithSpinner>
        </template>
        <i class="pi pi-times" @click="feedbackOpen = false" />
      </div>
      <div
        v-else-if="!feedbackOpen && !feedbackSent"
        class="feedback__hidden"
        @click="feedbackOpen = true"
      >
        {{ $t('endResultsGiveFeedback') }}
      </div>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref } from 'vue';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';
  import useFirebase from '@/composables/use-firebase';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import useToast from '@/composables/use-toast';
  import { useI18n } from 'vue-i18n';

  export default {
    name: 'Feedback',
    components: { BaseButtonWithSpinner },
    setup() {
      const { sendFeedback, getCurrentUser } = useFirebase();
      let { currentRoute } = useRouter();
      const store = useStore();
      const user = getCurrentUser();
      const { warningToast } = useToast();
      const { t } = useI18n();

      const feedbackRating = ref(null);
      const feedback = ref('');
      const feedbackOpen = ref(false);
      const feedbackSent = ref(false);
      const feedbackLoading = ref(false);

      onMounted(() => {
        setTimeout(() => {
          if (feedbackSent.value) {
            return;
          }
          feedbackOpen.value = true;
        }, 5000);
      });

      const handleSendFeedback = async () => {
        if (!feedbackRating.value && !feedback.value) {
          //show alert that it's empty
          warningToast(t('endResultsSendFeedbackWarning'));
          return;
        }

        feedbackLoading.value = true;
        try {
          await sendFeedback(
            Number(feedbackRating.value),
            feedback.value,
            String(currentRoute.value.params.gameId),
            store.state.company.id,
            store.state.company.name,
            user.email
          );

          feedbackSent.value = true;

          setTimeout(() => (feedbackOpen.value = false), 2000);
        } catch (e) {
          console.log(e);
        } finally {
          feedbackLoading.value = false;
        }
      };
      return {
        feedback,
        feedbackRating,
        feedbackOpen,
        feedbackSent,
        feedbackLoading,
        handleSendFeedback
      };
    }
  };
</script>

<style scoped lang="scss">
  .feedback {
    background: white;
    border-radius: global-vars(ui-default-measure);
    box-shadow: global-vars(ui-default-box-shadow--dark);

    &-wrapper {
      width: auto;
      height: auto;
      position: fixed;
      bottom: global-vars(ui-default-measure2x);
      right: global-vars(ui-default-measure2x);

      &--open {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);

        .feedback {
          padding: 24px 40px;
          @include center();
        }
      }
    }

    i {
      position: absolute;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      top: -8px;
      right: -24px;
      cursor: pointer;
    }

    &__open {
      position: relative;
      padding: 6px;
      width: 380px;
      max-width: 100%;
    }

    &__hidden {
      padding: global-vars(ui-default-measure);
      cursor: pointer;
      @include font(14px, 600);
    }

    textarea {
      margin-bottom: global-vars(ui-default-measure2x);
    }

    &__title {
      @include font(16px, 600);
    }

    &__rating {
      margin-bottom: global-vars(ui-default-measure2x);

      &-item {
        border: 1px solid color(gray);
        width: 36px;
        height: 36px;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.2s ease;

        &:not(:last-of-type) {
          margin-right: 4px;
        }

        &:hover {
          border: 1px solid color(accent);
          background: color(accent);
          color: white;
        }

        &--active {
          @include font(16px, 600);
          border: 1px solid color(accent);
          background: color(accent);
          color: white;
        }
      }
    }
  }
</style>

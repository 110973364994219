<template>
  <div class="add-admin__wrapper">
    <div class="add-admin" @click="visible = true">
      {{ $t('common.addAdmin') }}
    </div>
    <Dialog v-model:visible="visible" class="add-admin__modal">
      <template #header>
        <h3>{{ $t('common.addAdmin') }}</h3>
      </template>

      <DropdownSelect
        v-model="newAdmin"
        :options="filteredPlayers"
        option-label="name"
        option-value="id"
      />
      <template #footer>
        <BaseButtonWithSpinner
          :disabled="!newAdmin"
          @click="
            $emit('change', newAdmin);
            visible = false;
          "
          :loading="newAdminLoading"
        >
          {{ $t('funTeamBidding.teams.change') }}
        </BaseButtonWithSpinner>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';

  export default defineComponent({
    name: 'AddAdmin',
    components: { BaseButtonWithSpinner },
    props: {
      players: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        visible: false,
        newAdmin: '',
        newAdminLoading: false
      };
    },
    computed: {
      filteredPlayers() {
        return this.players.filter(p => !p.admin);
      }
    },
    emits: ['change']
  });
</script>

<style scoped lang="scss">
  .add-admin {
    height: global-vars(ui-default-measure3x);
    background: white;
    border-radius: global-vars(ui-default-measure);
    box-shadow: global-vars(ui-default-box-shadow--dark);
    cursor: pointer;
    padding: 4px;
    @include font(12px, 600, 15px);
  }
</style>
